import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-campaigns';
import CampaignCard       from 'app/components/common/campaign-card';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import ScrollTable        from 'app/components/common/scroll-table';
import StandardSelect     from 'app/components/common/standard-select';
import ModalNewCampaign   from 'app/components/company-admin/campaigns/modal-new-campaign';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import RequireRole        from 'app/components/gating/require-role';
import PageLoading        from 'app/components/layout/page-loading';
import {
  CampaignStatuses,
}                         from 'app/constants';
import campaignsHelper    from 'app/helpers/campaigns';
import Ps                 from 'app/helpers/publish-statuses';
import Metrics            from 'app/metrics';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-campaigns';

const StatusLabels = {
  [CampaignStatuses.ACTIVE]: 'Live',
  [CampaignStatuses.ENDED]: 'Ended',
  [CampaignStatuses.UPCOMING]: 'Upcoming',
};
const activityOptions = [
  {label: 'Donations',    value: 'give'},
  {label: 'Volunteering', value: 'vol'},
];
const activityOptionsWithDrive = [
  ...activityOptions,
  {label: 'Goods Drive',  value: 'drive'},
];
const statusOptions = [
  {label: 'Live',         value: 'active'},
  {label: 'Upcoming',     value: 'upcoming'},
  {label: 'Ended',        value: 'ended'},
];

const getFormattedDate = (campaign) => {
  if (!campaign.endDate) return 'Ongoing';
  return moment(campaign.endDate).format('MM/DD/YYYY');
};

class PageCadminCampaigns extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
    };

    this.onSelectPage              = this.onSelectPage.bind(this);
    this.onChangeActivity          = this.onChangeActivity.bind(this);
    this.onChangeStatus            = this.onChangeStatus.bind(this);
    this.onChangePrimaryGroup      = this.onChangePrimaryGroup.bind(this);
    this.onCloseNewModal           = this.onCloseNewModal.bind(this);
    this.onClickNewCampaign        = this.onClickNewCampaign.bind(this);
  }

  get newModalDefaults() {
    const {queryParams} = this.props;
    if (queryParams.activity === 'give')  return {hasGive: true,  hasDrive: false, hasVol: false};
    if (queryParams.activity === 'drive') return {hasGive: false, hasDrive: true,  hasVol: false};
    if (queryParams.activity === 'vol')   return {hasGive: false, hasDrive: false, hasVol: true};
    return {hasGive: false, hasDrive: false, hasVol: false};
  }

  get drivesEnabled() {
    return this.props.company?.features?.drive || false;
  }

  get hasGroups() {
    return !!this.props.company?.features?.groups;
  }

  onClickNewCampaign() {
    this.setState({showNewModal: true});
  }

  onCloseNewModal() {
    this.setState({showNewModal: false});
  }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onChangePrimaryGroup(group) {
    this.props.setQueryParams({primaryGroupId: group?.id, page: 1});
  }

  onChangeActivity(activity) {
    this.props.setQueryParams({activity, page: 1});
  }

  onChangeStatus(status) {
    this.props.setQueryParams({status, page: 1});
  }

  renderFilters() {
    const {queryParams} = this.props;
    const useActivityOptions = this.drivesEnabled ? activityOptionsWithDrive : activityOptions;
    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Activity</label>
          <StandardSelect options={useActivityOptions} onSelect={this.onChangeActivity} value={queryParams.activity} label="All" allowClear />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Status</label>
          <StandardSelect options={statusOptions} onSelect={this.onChangeStatus} value={queryParams.status} label="All" allowClear />
        </div>
        {this.hasGroups && (<>
          <div className={`ca-main-filters-filter`}>
            <label className="ca-main-filters-filter-label">Primary Group</label>
            <EntityInput.Group onChange={this.onChangePrimaryGroup} groupId={queryParams.primaryGroupId} />
          </div>
        </>)}
      </div>
    );
  }

  renderStatusCell(campaign) {
    const isPublished = campaign.publishStatus === Ps.ACTIVE;
    if (isPublished) {
      const status = campaignsHelper.getStatus(campaign);
      return <td className={`col-status ${status}`}>{StatusLabels[status]}</td>;
    }
    return <td className={`col-status`}>{Ps.name(campaign.publishStatus)}</td>;
  }

  renderCampaigns() {
    const { company, campaigns } = this.props;
    // if (!campaigns) return null;

    return (
      <ScrollTable tableClassName="ca-box-table campaigns">
        <thead>
          <tr>
            <th>Name</th>
            <th>Activity</th>
            <th>Status</th>
            <th className="right nowrap">End Date</th>
            <th className="min-200">Nonprofits</th>
            {this.hasGroups && (
              <th className="min-200">Primary Group</th>
            )}
            <th className="right nowrap">$ Raised</th>
            {this.drivesEnabled && <th className="right nowrap"># Goods</th>}
          </tr>
        </thead>
        <tbody>
          {(campaigns || []).map((c) => {
            // const companyAmount = c.grantedAmountInCents + c.matchedAmountInCents;
            const giveNum  = c.hasGive  ? numeral(c.totalRaisedAmount / 100).format('$0,0') : '';
            const driveNum = c.hasDrive ? numeral(c.driveTotalQuantity || 0).format('0,0')  : '';
            const isActiveish = Ps.isActiveish(c.publishStatus);
            const menu = (
              <EllipsisMenu usePortal>
                <Link href={paths.campaign(c.id)}><Icon.BrowserPageText />Employee View</Link>
                <Link href={paths.cadminCampaignsView(company.slug, c.id)}><Icon.Cog1 />Manage</Link>
                <Link href={paths.cadminEditCampaign(company.slug, c.id)}><Icon.Pencil />Edit</Link>
                {isActiveish && (<>
                  {c.hasGive && (
                    <Link href={paths.cadminMatches(company.slug, {campaignId: c.id})}><Icon.AccountingBills />View Donations</Link>
                  )}
                  {c.hasDrive && (
                    <Link href={paths.cadminDriveDonations(company.slug, {campaignId: c.id})}><Icon.ProductsGiftGive />View Drive Donations</Link>
                  )}
                  <RequireRole>
                    <Link href={paths.cadminAnalytics(company.slug, {campaignId: c.id, groupBy: 'employeeId'}, Metrics.presets.campaigns.key)}><Icon.AnalyticsBars />Analytics</Link>
                  </RequireRole>
                </>)}
              </EllipsisMenu>
            );
            return (
              <tr key={c.id}>
                <td>
                  <div className="menu-cell">
                    <Link className="pink-hover" href={paths.cadminCampaignsView(company.slug, c.id)}>{c.name}</Link>
                    {menu}
                  </div>
                </td>
                <td className="ca-campaigns-col-activities">
                  {c.hasGive && <Icon.AccountingBills className="purple" />}
                  {c.hasDrive && <Icon.ProductsGiftGive className="green" />}
                  {c.hasVol && <Icon.HandExpand className="orange" />}
                </td>
                {this.renderStatusCell(c)}
                <td className="right">{getFormattedDate(c)}</td>
                <td className="min-200">
                  {c.aNonprofit && (
                    <Link className="pink-hover" href={paths.nonprofit(c.aNonprofit)}>{c.aNonprofit.name}</Link>
                  )}
                  {(c.nonprofitCount >= 2) && (` + ${c.nonprofitCount - 1}`)}
                </td>
                {this.hasGroups && (
                  <td className="min-200">
                    {c.primaryGroup && (
                      <Link href={paths.group(c.primaryGroup.id)} className="pink-hover">{c.primaryGroup.name}</Link>
                    )}
                  </td>
                )}
                <td className="right">{giveNum}</td>
                {this.drivesEnabled && <td className="right">{driveNum}</td>}
              </tr>
            )
          })}
        </tbody>
      </ScrollTable>
    );
  }

  renderCampaignCards(campaigns, title, cardProps = {}) {
    if (!campaigns || !campaigns.length) return null;
    return (
      <div className="ca-campaigns-section-live-campaigns">
        {title}
        <div className="ca-campaigns-section-live-campaigns-cards">
          {campaigns.map(lc => (
            <div key={lc.id} className="campaigns-cards-card">
              <CampaignCard campaign={lc} {...cardProps} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const {showNewModal} = this.state;
    const {company, liveCampaigns, pagination, queryParams, attnNum} = this.props;
    if (!company) return <PageLoading />;
    const liveTitle = <h4 className="ca-campaigns-section-title">Live Campaigns</h4>;

    return (
      <CadminLayout className="ca-campaigns" company={company} activeItem="campaigns">
        <Meta title="Campaigns | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Campaigns</h1>
          <div className="ca-main-head-actions">
            <button onClick={this.onClickNewCampaign} className="btn special purple">Create New</button>
          </div>
        </div>
        {this.renderFilters()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Campaigns</h1>
            <div className="ca-box-header-controls"></div>
          </div>
          <div className="ca-box-tabs">
            <div className="ca-box-tabs-tabs">
              {Ps.tabList.map((ps) => {
                const isActive = queryParams.publishStatus === ps;
                const showNum = !!(attnNum && (ps === Ps.PENDING));
                return (
                  <Link key={ps} className={`ca-box-tabs-tab ${isActive ? 'active' : ''}`} href={paths.cadminCampaigns(company.slug, {...queryParams, page: null, publishStatus: ps})}>
                    {Ps.name(ps)}
                    {showNum && <span className="ca-box-tabs-tab-attn">{attnNum}</span>}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderCampaigns()}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>

        {this.renderCampaignCards(liveCampaigns, liveTitle)}
        {showNewModal && <ModalNewCampaign company={company} onClose={this.onCloseNewModal} defaults={this.newModalDefaults} allowedGive={company.features.match} allowedVol={company.features.vol} allowedDrive={this.drivesEnabled} />}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  attnNum: CadminSlx.attnNums(state)?.pendingCampaigns || 0,

  campaigns: PageSlx.campaigns(state),
  liveCampaigns: PageSlx.liveCampaigns(state),

  searchPending: PageSlx.searchPending(state),
  queryParams: PageSlx.queryParams(state),
  pagination: PageSlx.pagination(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (params) => dispatch(PageAx.setQueryParams(params)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminCampaigns);
