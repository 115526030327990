import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ScrollTable        from 'app/components/common/scroll-table';
import CadminLayout       from 'app/components/company-admin/layout/';
import TopLineStats       from 'app/components/company-admin/top-line-stats';
import PageLoading        from 'app/components/layout/page-loading';
import Ps                 from 'app/helpers/publish-statuses';
import utils              from 'app/helpers/utils';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-groups';

class PageCadminGroups extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  renderSummary() {
    const { summary } = this.props;
    const stats = [
      {icon: null, key: 'Employees in Groups', valType: 'int',     val: summary?.membersCount},
      {icon: null, key: 'Group Leads',         valType: 'int',     val: summary?.adminsCount},
      {icon: null, key: 'Group Events',        valType: 'int',     val: summary?.eventsCount},
      {icon: null, key: 'Participation',       valType: 'percent', val: summary?.participation},
    ];
    return <TopLineStats stats={stats} color="groups" />;
  }

  renderGroups() {
    const {company, groups} = this.props;

    return (
      <ScrollTable tableClassName="ca-box-table ca-groups-table">
        <thead>
          <tr>
            <th>Group Name</th>
            <th className="right">Members</th>
            <th className="min-140">Lead</th>
            <th className="right">Events</th>
            <th>Section</th>
          </tr>
        </thead>
        <tbody>
          {(groups || []).map((group) => {
            const admin = group.adminEmployee;
            const adminOthers = Math.max(group.adminCount - 1, 0);
            const isActiveish = Ps.isActiveish(group.publishStatus);
            return (
              <tr key={group.id}>
                <td>
                  <div className="menu-cell">
                    <div className="ca-groups-table-logo-name-con">
                      <img className="ca-groups-table-logo" src={utils.s3Url(group.logoImgPath)} />
                      <Link className="pink-hover" href={paths.cadminGroupsView(company.slug, group.id)}>{group.name}</Link>
                    </div>
                    <EllipsisMenu usePortal>
                      <Link href={paths.group(group.id)}><Icon.BrowserPageText />Employee View</Link>
                      <Link href={paths.cadminGroupsView(company.slug, group.id)}><Icon.Cog1 />Manage</Link>
                      <Link href={paths.cadminGroupsEdit(company.slug, group.id)}><Icon.Pencil />Edit</Link>
                      {isActiveish && (<>
                        <Link href={paths.cadminGroupEmployees(company.slug, {groupId: group.id})}><Icon.UserMultipleNeutral2 /> Members</Link>
                      </>)}
                    </EllipsisMenu>
                  </div>
                </td>
                <td className="right">{group.employeeCount}</td>
                <td>
                  {admin ? `${admin.firstName} ${admin.lastName}` : ''}
                  {adminOthers ? ` +${adminOthers}` : ''}
                </td>
                <td className="right">{group.volEventCount}</td>
                <td>{group.groupType?.name}</td>
              </tr>
            )
          })}
        </tbody>
      </ScrollTable>
    );
  }

  render() {
    const {company, queryParams, attnNum} = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-groups" company={company} activeItem="groups">
        <Meta title="Groups | Millie" />

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Groups</h1>
          <div className="ca-main-head-actions">
            <Link href={paths.cadminGroupsSettings(company.slug)} className="btn secondary slate">Settings</Link>
            <Link href={paths.cadminGroupsNew(company.slug)} className="btn special groups">Create Group</Link>
          </div>
        </div>

        {this.renderSummary()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Groups</h1>
            <div className="ca-box-header-controls" />
          </div>
          <div className="ca-box-tabs">
            <div className="ca-box-tabs-tabs">
              {Ps.tabList.map((ps) => {
                const isActive = queryParams.publishStatus === ps;
                const showNum = !!(attnNum && (ps === Ps.PENDING));
                return (
                  <Link key={ps} className={`ca-box-tabs-tab ${isActive ? 'active' : ''}`} href={paths.cadminGroups(company.slug, {...queryParams, publishStatus: ps})}>
                    {Ps.name(ps)}
                    {showNum && <span className="ca-box-tabs-tab-attn">{attnNum}</span>}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderGroups()}
          </div>
        </div>
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  attnNum: CadminSlx.attnNums(state)?.pendingGroups || 0,

  summary: PageSlx.summary(state),
  groups: PageSlx.groups(state),
  queryParams: PageSlx.queryParams(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminGroups);
