
import CampaignsAx           from 'app/actions/campaigns';
import CadminBuilderAx       from 'app/actions/company-admin/builder-campaign';
import CadminCompaniesAx     from 'app/actions/company-admin/companies';
import VolEventsAx           from 'app/actions/vol-events';

const Types = {
  LOAD: 'CADMIN_PAGE_NEW_CAMPAIGN_LOAD',
};

const attrsFromQuery = (query) => {
  const attrs = {};
  if (query.hasDrive === 'true')  attrs.hasDrive = true;
  if (query.hasDrive === 'false') attrs.hasDrive = false;
  if (query.hasGive  === 'true')  attrs.hasGive  = true;
  if (query.hasGive  === 'false') attrs.hasGive  = false;
  if (query.hasVol   === 'true')  attrs.hasVol   = true;
  if (query.hasVol   === 'false') attrs.hasVol   = false;
  return attrs;
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    // clear previous
    dispatch(CadminBuilderAx.setCampaignId(null));
    const {templateId} = query;
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      (templateId ? dispatch(CampaignsAx.get(templateId)) : undefined),
      (templateId ? dispatch(VolEventsAx.search({campaignId: templateId, includeEnded: true})) : undefined),
    ]).then(([[{company}], {campaign={}, driveGoods=[]}={}]) => {
      const campObj = {...attrsFromQuery(query), ...campaign, timezone: company.timezone};
      return dispatch(CadminBuilderAx.setCampaign(campObj, driveGoods, true));
    });
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
